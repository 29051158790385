<template>
   <section class="cont checkIn-box">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>PMS</el-breadcrumb-item>
            <el-breadcrumb-item>{{fromName === 'roomState' ? "房态中心" : "预定管理"}} </el-breadcrumb-item>
            <el-breadcrumb-item v-if="fromName === 'roomState'" to="/room_state">实时房态</el-breadcrumb-item>
            <el-breadcrumb-item v-else to="/reserve_list">预定列表 </el-breadcrumb-item>
            <el-breadcrumb-item> {{fromName === 'roomState' ? "办理入住" : "预定转入住"}}</el-breadcrumb-item>
         </el-breadcrumb>
         <el-button  type="primary" plain @click="goBack">返回</el-button>
      </el-row>
      <el-row class="cont-form-box">
         <el-row v-if="fromName === 'reserve'" class="reserve-box">
            <el-col :span="5" style="padding: 20px 23px;">
               <ul class="reserveUl">
                  <li>预定单号：<span>{{reserveInfo.reserveId}}</span></li>
                   <li>(预定{{reserveInfo.reserveNum}}--排房 {{reserveInfo.rowHouseNum}}--未排房{{ reserveInfo.reserveNum && reserveInfo.rowHouseNum ? reserveInfo.reserveNum- reserveInfo.rowHouseNum : '0' }}--入住{{checkinCount}})</li>
               </ul>
            </el-col>
            <el-col :span="19" class="box-tag">
               <div class="reserveTags" v-for="(item,index) in tagList" :key="index"  size="medium" @click="tagEvent(item)" :class="item.roomNo == ruleForm.roomNo ? 'roomNoAdd' : 'roomNoDel'" >
                  <div>
                     <div>{{item.roomNo}}</div>
                     <div>{{item.roomTypeName}}</div>
                  </div>
                  <div>{{ checkInStateEvent(item.checkInState) }}</div>
               </div>
            </el-col>
         </el-row>
         <el-form class="form-box" :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-row class="form-row">
               <el-form-item label="房间类型" :prop="disabledRoom ? '' : 'roomTypeName'">
                  <el-input v-model="ruleForm.roomTypeName" disabled></el-input>
               </el-form-item>
               <el-form-item label="房间号" :prop="disabledRoom ? '' : 'roomNo'">
                  <el-input v-model="ruleForm.roomNo" disabled ></el-input>
               </el-form-item>
            </el-row>
            <el-row class="form-row">
               <el-form-item label="入住时间">
                  <el-date-picker
                        v-model="ruleForm.beginTime" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" placeholder="选择日期时间" :disabled="true">
                  </el-date-picker>
               </el-form-item>
               <el-form-item label="入住天数" :prop="disabledPerson ? '' : 'checkDays'">
                  <el-input v-model.number="ruleForm.checkDays" oninput="value=value.replace(/[^\d]/g,'')" :disabled="disabledPerson"></el-input>
               </el-form-item>
            </el-row>
            <el-row class="form-row">
               <el-form-item label="证件类型">
                  <el-select v-model="ruleForm.checkinPerson.certificateType" :placeholder="$t('msg.select')" clearable>
                     <el-option
                           v-for="item in certificateTypeOpt"
                           :key="item.value" :label="item.label" :value="item.value">
                     </el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="入住人姓名" :prop="disabledPerson ? '' : 'checkinPerson.name'" clearable>
                  <el-input v-model="ruleForm.checkinPerson.name"></el-input>
               </el-form-item>
            </el-row>
            <el-row class="form-row">
               <el-form-item label="证件号码" prop="checkinPerson.certificateNo"  >
                  <el-input v-model="ruleForm.checkinPerson.certificateNo" :maxlength="18" clearable></el-input>
               </el-form-item>
               <el-form-item label="性别">
                  <el-select v-model="ruleForm.checkinPerson.gender" :placeholder="$t('msg.select')">
                     <el-option
                           v-for="item in genderOpt"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                     </el-option>
                  </el-select>
               </el-form-item>
            </el-row>
            <el-row class="form-row">
               <el-form-item label="出生日期" prop="checkinPerson.birthday">
                  <el-date-picker
                        v-model="ruleForm.checkinPerson.birthday" type="date"
                        value-format="yyyy-MM-dd" placeholder="选择日期">
                  </el-date-picker>
               </el-form-item>
               <el-form-item label="手机号码" :prop="disabledPerson ? '' : 'checkinPerson.mobile'" >
                  <el-input v-model="ruleForm.checkinPerson.mobile"  oninput="value=value.replace(/[^\d]/g,'')" :maxlength="11"></el-input>
               </el-form-item>
            </el-row>
            <el-row class="form-row">
               <el-form-item label="支付状态">
                  <el-select v-model="ruleForm.orderDTO.orderStatus" :placeholder="$t('msg.select')">
                     <el-option
                           v-for="item in orderStatusOpt"
                           :key="item.value" :label="item.label" :value="item.value">
                     </el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="收款金额" prop="payAmount" v-show="ruleForm.orderDTO.orderStatus === 'HAVE_PAID'">
                  <el-input-number v-model="ruleForm.payAmount"  :min="0" label="描述文字"></el-input-number>
                  <!--                  <el-input  v-model="ruleForm.payAmount"></el-input>-->
               </el-form-item>
            </el-row>
            <el-row class="bottom-btn">
               <el-button  @click="handleReset">重置</el-button>
               <el-button  type="primary" @click="saveCheckIn">确定</el-button>
            </el-row>
         </el-form>
      </el-row>
   </section>
</template>

<script>
import { urlObj } from '@/api/interface'
import { pms } from '@/api/interface/pms'
import { dateFactory, verificationRule } from '@/common/js/common'
export default {
   data(){
      let validBirthday = (rule, value, callback) => {
         if (value === '') {
            callback(new Error('请选择生日日期'))
         } else if (dateFactory.compareTime(value, new Date())){
            callback(new Error('生日不能大于当前日期'))
         } else {
            callback()
         }
      };
      let validCard = (rule, value, callback) => {
         if (value === '') {
            callback(new Error('请输入身份证号'))
         }else if (verificationRule.certificateNo(value)) {
            callback(new Error('证件号格式错误'))
         } else {
            callback()
         }
      }
      let validPay = (rule, value, callback) => {
         if (this.ruleForm.orderDTO.orderStatus === 'HAVE_PAID' && value === undefined) {
            callback(new Error('请输入支付金额'))
         }else {
            callback()
         }
      }
      let validMobile = (rule, value, callback) => {
         if (value === '') {
            callback(new Error('请输入手机号'))
         }else if (verificationRule.mobile(value)){
            callback(new Error('手机号格式错误'))
         } else {
            callback()
         }
      }
      return{
         fromName: 'roomState',        // 来自哪个页面
         disabledRoom: false,          // 房间类型禁止修改
         disabledPerson: false,        // 入住人类型禁止修改
         tagList: [],                  // 排房列表
         reserveInfo: {},              // 预定人信息
         certificateTypeOpt: [         // 身份证类型
            { label: '身份证', value: 'IDCARD' },
            { label: '护照', value: 'PASSPORT' },
            { label: '其他', value: 'OTHER' },
         ],
         genderOpt: [                  // 性别
            { label: '男', value: 'MAN' },
            { label: '女', value: 'WOMAN' },
            { label: '秘密', value: 'SECRET' }
         ],
         orderStatusOpt: [            // 订单状态
            { label: '待支付', value: 'PENDING_PAYMENT' },
            { label: '已支付', value: 'HAVE_PAID' }
         ],
         ruleForm: {
            roomNo: '',               // 房间号
            roomId: '',               // 房间ID
            beginTime: '',            // 入住时间
            endTime: '',              // 离开时间
            hotelId: '',              // 酒店ID
            roomTypeId: '',           // 房型ID
            roomTypeName: '',         // 房型名称
            checkInState: 'CHECKIN',  // 入住状态
            checkDays: '',            // 入住天数
            payAmount: '',            // 收款金额
            checkinPerson: {          // 入住人信息
               certificateType: 'IDCARD', // 证件类型
               name: '',              // 入住人姓名
               certificateNo: '',     // 身份证号
               gender: 'MAN',         // 性别
               mobile: '',            // 手机号
               birthday: '',          // 生日
            },
            orderDTO: {               // 订单信息
               orderType: 'HOTEL',
               orderStatus: 'PENDING_PAYMENT',     // 支付状态
               storeId: '',           // 商户ID
               tenantId: '',          // 租户ID
               hotelId: ''            // 酒店ID
            },
         },
         rules: {
            checkinPerson: {
               name: [
                  { required: true, message: '请输入入住人姓名', trigger: 'blur' }
               ],
               certificateNo: [
                  { required: true, validator: validCard, trigger: 'blur' }
               ],
               birthday: [
                  { required: true, validator: validBirthday, trigger: 'change' }
               ],
               mobile: [
                  { required: true, validator: validMobile, trigger: 'blur' }
               ],
            },
            roomTypeName: [
               { required: true, message: '请输入房间类型', trigger: 'blur' }
            ],
            roomNo: [
               { required: true, message: '请选择房间号', trigger: 'change' }
            ],
            beginTime: [
               { required: true, trigger: 'change' }
            ],
            checkDays: [
               { required: true, message: '请输入入住天数', trigger: 'blur' }
            ],
            payAmount: [
               { validator: validPay, trigger: 'blur' }
            ]
         },
         checkinCount:0,
         add_success:'入住成功',
         check_out:"已退房不可办理入住",
         check_in:"此房号已入住不可再办理入住"
      }
   },
   beforeRouteEnter(to, from, next) {
      if (from.name === 'roomState') {
         next(vm => {
            vm.fromName = from.name
            vm.disabledRoom = true
            vm.getRoomInfo()
         })
         return;
      }
      if (from.name === 'reserve') {
         next(vm => {
            vm.fromName = from.name
            vm.disabledPerson = true
            vm.getReserveInfo()
         })
         return
      }
      next()
   },
   mounted() {
      let userinfo = JSON.parse(sessionStorage.getItem('userInfo'))
      this.ruleForm.orderDTO.tenantId = userinfo.tenantId || ''
      this.ruleForm.orderDTO.storeId = userinfo.storeId || ''
   },
   beforeDestroy() {
      sessionStorage.removeItem('roomInfo')
      sessionStorage.removeItem('reserveInfo')
   },
   methods: {
      // 获取房间信息
      getRoomInfo(){
         let roomInfo = JSON.parse(sessionStorage.getItem('roomInfo'))
         this.ruleForm.hotelId = roomInfo.hotelId
         this.ruleForm.orderDTO.hotelId = roomInfo.hotelId
         this.ruleForm.roomNo = roomInfo.roomNo
         this.ruleForm.roomId = roomInfo.id
         this.ruleForm.roomTypeId = roomInfo.roomTypeId
         this.ruleForm.roomTypeName = roomInfo.roomTypeName
         this.ruleForm.beginTime = dateFactory.dateFormat(true)
      },
      // 获取预定人信息
      getReserveInfo() {
         let reserveInfo = JSON.parse(sessionStorage.getItem('reserveInfo'))
         this.reserveInfo = reserveInfo
         this.ruleForm.roomTypeName = reserveInfo.roomTypeName
         this.ruleForm.beginTime = reserveInfo.reserveDTO.beginTime
         this.ruleForm.roomTypeId = reserveInfo.reserveDTO.roomTypeId
         this.ruleForm.checkinPerson.name = reserveInfo.reserveDTO.reserveName
         this.ruleForm.checkinPerson.mobile = reserveInfo.reserveDTO.mobile
         this.ruleForm.hotelId = reserveInfo.hotelId
         this.ruleForm.orderDTO.hotelId = reserveInfo.hotelId
         this.ruleForm.checkDays = dateFactory.getDistanceDays(this.ruleForm.beginTime, reserveInfo.reserveDTO.endTime)
         this.getRowHouseList(reserveInfo.id)
      },
      // 获取预定单入住房号
      getRowHouseList(id) {
         const url = pms.getRowHouseList + `/${id}`
         this.$axios.get(url, {}).then(res => {
            if (res.success) {
               this.tagList = res.records.orderRowhouseList
               if(this.tagList.length == 0){
                  this.$alert('未排房无法办理入住，请前往进行排房', '', {
                     confirmButtonText: '确定',
                     type: 'warning',
                     callback: action => {
                        this.goBack()
                     }
                  })
               }
               this.tagList.forEach(item=>{
                  if(item.checkInState === "CHECKIN"){
                     this.checkinCount +=1 //计算入住数
                  }
               })
            }
         })
      },
      //是否入住枚举
      checkInStateEvent(val){
         switch (val){
            case "NOT_CHECKIN":
               return val = "未入住"
            case "CHECKIN":
               return val = "已入住"
            case "CHECKOUT":
               return val = "已退房"
         }
      },
      //获取选中房间号
      tagEvent(item){
         //已退房不可办理入住
         if(item.checkInState != "NOT_CHECKIN" ){
            this.$alert(item.checkInState == "CHECKOUT" ? this.check_out : this.check_in, '', {
               confirmButtonText: this.confirm,
               type: 'error',
            })
         }else{
            this.ruleForm.roomNo = item.roomNo
            this.ruleForm.roomId = item.id
         }
      },
      // 保存入住
      saveCheckIn(){
         this.$refs.ruleForm.validate(valid => {
            if (valid) {
               let url = pms.pmsCheckIn
               let param = this.ruleForm
               param.endTime = dateFactory.getDistanceAppoint(this.ruleForm.beginTime, '+', this.ruleForm.checkDays)
               if (this.fromName === 'reserve') {
                  url = pms.reserveToCheckIn
                  param.id = this.ruleForm.roomId
                  delete param.roomId
               }
               this.$axios.post(url, param, 'json').then(res => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: this.add_success,
                        type: 'success'
                     })
                     this.goBack()
                  }
               })
            }
         })
      },
      // 清空表单
      handleReset() {
         if(this.reserveInfo){
            this.ruleForm.checkinPerson.certificateNo = ""
            this.ruleForm.checkinPerson.birthday = ""
         }else{
            this.$refs.ruleForm.resetFields()
            this.ruleForm.checkinPerson.certificateType = 'IDCARD'
            this.ruleForm.checkinPerson.gender = 'MAN'
            this.ruleForm.orderDTO.orderStatus = 'PENDING_PAYMENT'
            this.getRoomInfo()
         }
      },
      goBack() {
         this.$router.go(-1)
      }
   },
}
</script>

<style scoped lang="scss">
.checkIn-box{
   .box-tag{
      .reserveTags{
         display: inline-block;
         border-radius: 5px;
         font-size: 12px;
         color: black;
         text-align: center;
         width: 100px;
         height: 75px;
         line-height: 26px;
         margin: 5px 5px 0px 0px;
         overflow: hidden;
         cursor: pointer;
      }
      .roomNoAdd{
         border:1px solid #7f7f7f;
         div:first-child{
            div:first-child{
               font-size: 17px;
               color: #757575;
            }
         }
         div:last-child{
            background-color: #7f7f7f;
            color: #FFFFFF;
         }
      }
      .roomNoDel{
         border:1px solid #0c7ffd;
         div:first-child{
            div:first-child{
               font-size: 17px;
               color:#0c7ffd;
            }
         }
         div:last-child{
            background-color: #0c7ffd;
            color: #FFFFFF;
         }
      }
   }
}


.reserveUl{
   list-style: none;
   li{
      margin: 10px 0px;
   }
   li:last-child{
      color: #0c7ffd;
   }
}
</style>
